import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { FaLine } from 'react-icons/fa';

import { LINE_LOGIN_URL } from '../config/env';

import { setOriginalUrl } from '@src/lib/originalUrl';

export default function LoginPage() {
  const router = useRouter();
  useEffect(() => {
    if (router.query.originalUrl) {
      setOriginalUrl(router.query.originalUrl as string);
    }
  }, [router.query]);

  return (
    <div className="grid place-items-center my-20 md:my-20 mx-2">
      <div
        className="            px-6 py-10 sm:px-10 sm:py-6
w-11/12 p-12 sm:w-8/12 md:w-6/12 lg:w-5/12 2xl:w-4/12
            bg-white rounded-lg"
      >
        <h2 className="text-3xl lg:text-4xl font-semibold text-center text-black">
          ログイン
        </h2>

        <Link href={LINE_LOGIN_URL}>
          <a className="text-gray-700">
            <button className="flex flex-row justify-center items-center p-3 my-6 space-x-2 w-full bg-gray-100 hover:bg-gray-200 rounded-sm text-md">
              <span className="text-green-500">
                <FaLine />
              </span>
              <div>LINE ログイン</div>
            </button>
          </a>
        </Link>
      </div>
    </div>
  );
}
